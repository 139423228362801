import React from 'react';
import '../css/photos.scss';
// import { Link } from 'react-router-dom';

const Photos = () => {

  return (
    <div id="photos">
      <div id="title">
        <h1>Photos</h1>
      </div>
      <div id="content">
        <div className='flex flex-wrap'>
<img className="h" alt='Duo Tamba 1' src='/images/creacirque/image0.jpeg' />
<img className="h" alt='Duo Tamba 2' src='/images/creacirque/image1.jpeg' />
  <img className="v" alt='Duo Tamba 3' src='/images/creacirque/image2.jpeg' />
  <img className="v" alt='Duo Tamba 4' src='/images/creacirque/image3.jpeg' />
  <img className="v" alt='Duo Tamba 5' src='/images/creacirque/image4.jpeg' />
  <img className="v" alt='Duo Tamba 6' src='/images/creacirque/image5.jpeg' />
  <img className="h" alt='Duo Tamba 7' src='/images/creacirque/image6.jpeg' />
  <img className="h" alt='Duo Tamba 8' src='/images/animations/duotamba1.jpg' />
  <img className="h" alt='Duo Tamba 9' src='/images/animations/duotamba2.jpg' />
<img className="h" alt='grimage 1' src='/images/animations/grimage1.jpg' />
<img className="h" alt='grimage 2' src='/images/animations/grimage2.jpg' />
<img className="h" alt='grimage 3' src='/images/animations/grimage3.jpg' />
<img className="h" alt='grimage 4' src='/images/animations/grimage4.jpg' />
<img className="h" alt='grimage 5' src='/images/animations/grimage5.jpg' />
<img className="h" alt='grimage 6' src='/images/animations/grimage6.jpg' />
<img className="h" alt='grimage 7' src='/images/animations/grimage7.jpg' />
<img className="h" alt='grimage 8' src='/images/animations/grimage8.jpg' />
<img className="h" alt='grimage 9' src='/images/animations/grimage9.jpg' />
<img className="h" alt='espace cirque 2' src='/images/animations/espace_cirque2.jpg' />
<img className="h" alt='espace cirque 3' src='/images/animations/espace_cirque3.jpg' />
<img className="h" alt='espace cirque 4' src='/images/animations/espace_cirque4.jpg' />
<img className="h" alt='sculpture de ballons' src='/images/animations/espace_cirque1.jpg' />
<img className="h" alt='echassiers' src='/images/animations/jonglerie1.jpg' />
        <img className="h" alt='jonglerie' src='/images/animations/jonglerie3.jpeg' />
        <img className="h" alt='jonglerie' src='/images/animations/jonglerie.jpg' />
        <video  width="250" controls>
            <source src='/images/animations/jonglerie.mp4' type="video/mp4" />
            Attention votre navigateur ne peut pas afficher cette vidéo
          </video>
        </div>
      </div>
    </div>
  )
};

export default Photos;
