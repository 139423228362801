import React from 'react';
import '../css/animations.scss';
// import { Link } from 'react-router-dom';

const Animations = () => {

  return (
    <div id="animations">
      <div id="title">
        <h1>Animations</h1>
      </div>
      <div id="content">
        <p>Voici un aperçu des animations que nous pouvons vous proposer.</p>
        <p><span className="section">Un artiste en jonglerie déambulation :</span> Avec ses massues, son diabolo ou son monocycle, ce jongleur apporte de l'animation et déclenche de l'émerveillement et des sourires sur son passage.</p>
        <div className='flex flex-wrap'>
        <img className="h" alt='jonglerie' src='/images/animations/jonglerie3.jpeg' />
        <img className="h" alt='jonglerie' src='/images/animations/jonglerie.jpg' />
        <video  width="250" controls>
            <source src='/images/animations/jonglerie.mp4' type="video/mp4" />
            Attention votre navigateur ne peut pas afficher cette vidéo
          </video>
        </div>
<p>
<span className="section">Un artiste en échassier d'accueil :</span> Du haut de ses grandes jambes, cet échassier se promène dans la foule et apporte la bonne humeur en accueillant la foule.
<div className='flex flex-wrap'>
<img className="h" alt='echassiers' src='/images/animations/jonglerie1.jpg' />
</div>
</p><p>
<span className="section">Un artiste en échassier + sculpture de ballons :</span> Du haut de ses grandes jambes, ce personnage déambule en offrant des sculptures de ballons colorées et originales.
</p><p><span className="section">Un artiste sculpteur de ballons :</span>  Cet artiste apportera couleur et gaieté en offrant fleurs, petits chiens, oursons, épées ou autre fantaisie sculptée en ballons multicolores. Cet artiste peut déambuler en personnage de clown ou de pirate.
<div className='flex flex-wrap'>
<img className="h" alt='sculpture de ballons' src='/images/animations/espace_cirque1.jpg' />
</div>
</p><p><span className="section">Un espace cirque :</span> Petits et grands pourront découvrir et s'initier aux diverses disciplines de cirque: la jonglerie avec les balles, les massues, le diabolo, le bâton du diable... ou l'équilibre avec les échasses, le pédalgo, le monocycle, la boule d'équilibre.... C'est l'occasion de se découvrir des capacités insoupçonnées et de renforcer sa confiance en soi!
<div className='flex flex-wrap'>
{/* <img className="h" alt='espace cirque 1' src='/images/animations/espace_cirque1.jpg' /> */}
<img className="h" alt='espace cirque 2' src='/images/animations/espace_cirque2.jpg' />
<img className="h" alt='espace cirque 3' src='/images/animations/espace_cirque3.jpg' />
<img className="h" alt='espace cirque 4' src='/images/animations/espace_cirque4.jpg' />
</div>
</p><p><span className="section">Une artiste grimeuse:</span> Grâce à ses pinceaux magiques, les enfants pourront se transformer en animal fantastique, super héros.oïnes ou tout autre personnage issu de leur imagination!
<div className='flex flex-wrap'>
<img className="h" alt='grimage 1' src='/images/animations/grimage1.jpg' />
<img className="h" alt='grimage 2' src='/images/animations/grimage2.jpg' />
<img className="h" alt='grimage 3' src='/images/animations/grimage3.jpg' />
<img className="h" alt='grimage 4' src='/images/animations/grimage4.jpg' />
<img className="h" alt='grimage 5' src='/images/animations/grimage5.jpg' />
<img className="h" alt='grimage 6' src='/images/animations/grimage6.jpg' />
<img className="h" alt='grimage 7' src='/images/animations/grimage7.jpg' />
<img className="h" alt='grimage 8' src='/images/animations/grimage8.jpg' />
<img className="h" alt='grimage 9' src='/images/animations/grimage9.jpg' />
</div>
</p><p>
  
</p><p><span className="section">Duo Tamba - Syncrétisme</span>

</p><p>
Syncrétisme est un spectacle où la magie du cirque rencontre la poésie de la musique. Deux personnages, maîtres de cérémonie très différents, nous invitent à une cérémonie inspirée des traditions lointaines ou imaginaires. Ceux-ci créent la musique pendant le spectacle à l'aide d'une percussion insolite (Hybride, entre le djembé et la bouille à lait) et diverses autres petites percussions qu’ils enregistrent via une Loop station créant ainsi un support sonore sur lequel ils font évoluer le spectacle.
Entre rythmes envoûtants et manipulation de corps et d’objets, les artistes tissent un lien entre les cultures, pour montrer que, malgré nos différences, nous partageons tous et toutes la même magie de la rencontre. Un voyage décalé pour découvrir que la diversité est une richesse précieuse, et que l’union de nos singularités crée des passerelles communes !
</p>
<div className='flex flex-wrap'>
<img className="h" alt='Duo Tamba 1' src='/images/creacirque/image0.jpeg' />
<img className="h" alt='Duo Tamba 2' src='/images/creacirque/image1.jpeg' />
  <img className="v" alt='Duo Tamba 3' src='/images/creacirque/image2.jpeg' />
  <img className="v" alt='Duo Tamba 4' src='/images/creacirque/image3.jpeg' />
  <img className="v" alt='Duo Tamba 5' src='/images/creacirque/image4.jpeg' />
  <img className="v" alt='Duo Tamba 6' src='/images/creacirque/image5.jpeg' />
  <img className="h" alt='Duo Tamba 7' src='/images/creacirque/image6.jpeg' />
  <img className="h" alt='Duo Tamba 8' src='/images/animations/duotamba1.jpg' />
  <img className="h" alt='Duo Tamba 9' src='/images/animations/duotamba2.jpg' />
</div>
      </div>
    </div>
  )
};

export default Animations;
