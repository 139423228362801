import React from 'react';
// import { Link } from 'react-router-dom';

const Histo = () => {

  return (
    <div>
      <h2>L'historique</h2>          
	
      <h4>Créacirque existe depuis plus de 25 ans et fonctionne sans aucune subvention !</h4>
      <p>Créacirque est une école de cirque située dans l’enceinte de l’école Sainte -Marie à Jambes. Cette école de cirque existe depuis maintenant plus de 25 ans et développe divers cours de techniques de cirque tels que  la jonglerie,  l’acrobatie,  l’équilibre, le trapèze fixe, la circomotricité, le grand trampoline ainsi que les techniques d’expressions et de jeu.</p>
      <p>La création de cette école de cirque est une initiative de Manconi Katia et Vande Weghe Philippe, l’un professeur de circomotricité au Cirquétude d’Auderghem, l’autre professeur à L’Ecole Supérieure des Arts du Cirque de Bruxelles ainsi qu’artiste dans la Compagnie Les Argonautes et tous deux passionnés de cirque. Grâce à leur passion du travail et leur vision créative et fédératrice, ils ont su garder le cap et entretenir des générations de petits circassiens.</p>
      <p>C’est dans cette optique bienveillante que nous poursuivons leur travail. Avec entrain et créativité, nous nous efforçons, tout comme eux, de perdurer la passion du geste, la précision de l’instant et l’histoire du mouvement. D’ores et déjà, nous leur disons un grand merci !</p>

    </div>
)
};

export default Histo;
