import React from 'react';
/*import { Button} from 'reactstrap';*/
import { Link } from 'react-router-dom';
import Banner from '../components/Banner';
import Flyers from '../components/Flyers';
import '../css/home.scss';

const Home = () => {

  return (
    <div id="home">
      <div id="title">
        <h1>Accueil</h1>
      </div>
      {/* <TileLayout /> */}

      <div className="flex flex-wrap flex-space-between" id="content" >
      <div id="main-content">

      <Banner />

      <h2>Bonjour à toutes et à tous,</h2>


{/* <h3>Les spectacles de fin d'année se dérouleront en juin 2025.</h3> */}
{/* <h4>Pour nos stages d'été 2024, Créacirque s'associe avec de nombreuses associations et lieux culturels afin de mélanger le cirque avec d'autres disciplines artistiques et créer des ponts entre les arts!</h4> */}
{/* <h5>Venez les découvrir dans notre onglet "stages"!</h5> */}
<h5>Bienvenue sur le site de CREACIRQUE, l’Ecole de Cirque de Namur !</h5>
<p>Toutes nos activités se donnent dans la salle 2 de l'Institut Ste-Marie (227, rue de l'Aurore à 5100 Jambes).</p>
<p>L'envie qui nous anime est le développement des arts du cirque à travers la créativité,le partage, la perfomance et l'esthétisme.</p>
<p>N'hésitez à aller voir nos photos qui mettent en valeur le travail de nos talentueux élèves.</p>

{/* 
<Link className="color-black" to="stages-week-ends">
  <div id="stage" className="pane">  
    <h3>Stage de cirque et acrobatie</h3>
    <p>Du 3 au 7 mars 2025 de 9h00 à 16h00</p>
    <p>Salle 2 de l'Institut Sainte-Marie</p>
    <p>227, rue de l'Aurore à 5100 Jambes</p>
  </div>
</Link> */}

{/* <h4>Nouveau cours de PP (préparation physique) de cirque/gym pour adultes et ados tous les mercredis de 17h15 à 18h15</h4> */}
{/* <h4>Les cours débutent la semaine du 16 septembre 2024.</h4> */}
<h4>Les spectacles de fin d'année se dérouleront les 24 et 25 mai 2025.</h4>
<div>
  <p>
    {/* Bienvenue pour cette nouvelle saison 2024-25 remplie de talent, de partage, d'énergie , de créativité, de solidarité et de bonne humeur. */}
    Nous vous attendons nombreux et nombreuses. Toute aide est la bienvenue pour le bon déroulement des spectacles 😃.
  </p>
  <p>
    Au plaisir de vous revoir pour de nouvelles aventures circassiennes!</p>
</div>
{/* <div>Vous qui avez toujours révé de:
<ul>
<li>
  Marcher sur les mains.
  </li>
  <li>
  Rester une minute sans bouger sur les mains, la tête ou sur un bras.
  </li>
  <li>
  Vous relerver sans soucis d'un rétablissement grâce au "muscle up" (barres ou anneaux).
  </li>
  <li>
  Vous releverdu trépiedvers le porrieret de vous renforcer avec votre poids de corps(gainages spécifiques, abdos en dynamisme, positions en équilibre...).
  </li>
</ul>
</div> */}
{/* <p>Une vraie préparation physique dans un local attentif aux mesures Covid.</p>
<p>Le tout dans une ambiance cool, appliquée, bienveillante et encadré par un professionnel.</p>
<p>Intérressé.e.? Le 1 er cours est gratuit, nous vous attendons impatiemment!</p> */}


<Flyers />
{/* <div id="image-crea">
      <img alt="logo creacirque" src="/images/creacirque.jpg" />
    </div> */}

            
<p>Voici les documents pour l'année 2024-2025 à nous renvoyer complétés :</p>
      <div id="documents">
        <ul>
          <a target="_blank" rel="noopener noreferrer" download href="https://creacirque.be/Documents/Cr%C3%A9acirque%20-%20Droits%20image.pdf">
            <li className="documents__item">Droits à l'image</li>
          </a>
          <a target="_blank" rel="noopener noreferrer" download href="https://creacirque.be/Documents/Cr%C3%A9acirque%20-%20Licences.pdf">
            <li className="documents__item">Licences</li>
          </a>
          <a target="_blank" rel="noopener noreferrer" download href="https://creacirque.be/Documents/Cr%C3%A9acirque%20-%20Informations%20complémentaires.pdf">
            <li className="documents__item">Informations complémentaires</li>
          </a>
        </ul>
      </div>


      </div>
<div id="right-panel">
  <div id="address-crea" className="pane">
    <p>
      <b>Adresse du siège social de Créacirque</b><br/>
      9, rue de Nèvremont<br/>
      5070 Fosses-La-Ville<br/>
      Belgique
    </p>
    <p>Téléphone : +32 (0)474/05.21.96</p>
    <p>Numéro de compte: BE 95 0682 1788 4558</p>
    <p>Email: creacirque@gmail.com</p>
  </div>
  <div id="address-salle" className="pane">
    <p>
      <b>Adresse de la salle de Créacirque</b><br/>
      Salle 2 de l'Institut Sainte-Marie<br/>
      227, rue de l'Aurore<br/>
      5100 Jambes<br/>
      Belgique
    </p>
  </div>
  <div id="button-inscription" className="pane">  
    <Link to="les-cours" className="button pointer"
            >
            <span className="text">Inscription aux cours</span>
    </Link>
  </div>
  <Link className="color-black" to="stages-week-ends">
    <div id="stage" className="pane highlight">  
      <h3>Stage de cirque et acrobatie</h3>
      <p>Du 3 au 7 mars 2025 de 9h00 à 16h00</p>
      <p>Salle 2 de l'Institut Sainte-Marie</p>
      <p>227, rue de l'Aurore à 5100 Jambes</p>
    </div>
  </Link>
</div>
      </div>

    </div> 

  )
};

export default Home;


// <div id="stage" className="pane">  
// <Link to="stages-week-ends"><h3>Stage de cirque à Burdinne</h3></Link>
// <p>Du 21/10/2024 au 24/10/2024 de 09:00 à 16:00</p>
//   <h5>Ecole de Burdinne - Burdinne</h5>
//   <p>Stage d'initiation de cirque<br/>
//     Du 21 au 25 octobre 2024<br/>
//     En collaboration avec le service ATL de Burdinne <br/>
//     Nous découvrirons l’art du cirque selon ...</p>
// </div>
