import React from 'react';
// import { Link } from 'react-router-dom';

const Philo = () => {

  return (
    <div>
      <h2>La philosophie</h2>          
	
      <img alt='equipe' src="/images/creacirque/philo.jpg" />
      <p>Créacirque est une école de cirque située à Jambes. Elle existe depuis maintenant 20 ans et développe des cours de techniques de cirques divers comme l'acrobatie, la jonglerie, le jeu d'acteur, l'aérien, l'équilibre… Les activités proposées s'adressent aux enfants à partir de 5 ans, aux ados et aux adultes . 
      Notre philosophie est celle du « tous capable », chacun y trouve sa place, ses compétences peuvent être exploitées tout en se découvrant de nouvelles aptitudes insoupçonnées. Les Arts du Cirque ont cette capacité de stimuler la créativité et développer la conscience du corps et de l'autonomie. </p>
      <p>Créacirque désire, par le biais de ses cours, sensibiliser son public à des notions telles que la solidarité, l'entraide ainsi que  le respect de la personne et des différences.  Les motivations du public sont divergentes : découverte ou perfectionnement d'une technique, partager un moment d'échange convivial et créatif.</p>
      <h4>Créacirque mène plusieurs missions : </h4>
      <h5>Mission pédagogique :</h5>
      <ul>
        <li>enseigner les différentes techniques du cirque en prônant la philosophie du « tous capables ». Chacun y trouve sa place, ses compétences peuvent être exploitées tout en se découvrant de nouvelles aptitudes insoupçonnées.</li>
        <li>transmettre des valeurs telles que : tolérance, respect de soi et des autres, confiance, dépassement de soi et plaisir</li>
        <li>apprendre à appréhender les apprentissages avec plus de confiance</li>
        <li>contribuer à l'épanouissement de l'individu grâce à l'activité physique, la créativité et l'expression</li>
        <li>apprendre à s'accepter, découvrir les forces  et les faiblesses de chacun tout en appréciant la force du travail collectif</li>
      </ul>
      <h5>Mission artistique  :</h5>
      <ul>
        <li>développer la créativité de l'individu, l'aider à puiser dans son imaginaire afin de créer un univers propre à lui</li>
        <li>donner un maximum d'outils permettant à chacun de développer ses compétences et lui permettre de s'exprimer par le biais d'une technique artistique</li>
        <li>stimuler le potentiel créatif, physique, relationnel de chacun</li>
      </ul>
      <h5>Mission culturelle et citoyenne :</h5>
      <ul>
        <li>contribuer à la socialisation de l'individu grâce à un travail de groupe enrichissant et dans lequel les compétences de chacun sont valorisées.</li>
        <li>promouvoir l'accessibilité à tous, sans discrimination et favoriser la mixité socio- culturelle.</li>
        <li>instaurer un lieu d'échange et de rencontre en incitant les démarches collectives dans lesquelles le respect de l'autre et des différences sera mis en avant. </li>
        <li>développer les initiatives fédératrices et en collaboration avec d'autres associations, susciter l'envie de découvrir.</li>
      </ul>
    </div>
)
};

export default Philo;
