import React, {useState} from 'react';
import Histo from './Infos/Histo';
import Philo from './Infos/Philo';
import Team from './Infos/Team';
import '../css/infos.scss';
// import { Link } from 'react-router-dom';

const Infos = () => {
  const [activeTab, setActiveTab] = useState('histo');

  const handleTabClick = (tabName:string) => {
    setActiveTab(tabName);
};

  return (
    <div id="infos">
      <div id="title">
        <h1>Infos</h1>
      </div>
      <div className="menu-tabs">
        <a href="#histo"
                    className={activeTab === 'histo' ? 'active' : ''}
                    onClick={(e) => {
                        e.preventDefault();
                        handleTabClick('histo');
                    }}>L'historique</a>
        <a href="#philo" 
                    className={activeTab === 'philo' ? 'active' : ''}
                    onClick={(e) => {
                        e.preventDefault();
                        handleTabClick('philo');
                    }}>La philosophie</a>
        <a href="#team" 
                    className={activeTab === 'team' ? 'active' : ''}
                    onClick={(e) => {
                        e.preventDefault();
                        handleTabClick('team');
                    }}>L'équipe</a>
      </div>
      <div id="content" className="tabs">
        <div id="histo" className={activeTab === 'histo' ? 'active' : ''}><Histo /></div>
        <div id="philo" className={activeTab === 'philo' ? 'active' : ''}><Philo /></div>
        <div id="team" className={activeTab === 'team' ? 'active' : ''}><Team /></div>
      </div>
    </div>
  )
};

export default Infos;
