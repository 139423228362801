const Home = {
  name: 'Accueil',
  type: 'home',
  url: '',
  img: [],
};
const Courses = {
  name: 'Les cours',
  type: 'courses',
  url: 'les-cours',
  more: undefined
};
const Stages = {
  name: 'Stages & Weekends',
  type: 'stages',
  url: 'stages-week-ends',
  more: undefined,
};
const Photos = {
  name: 'Photos',
  type: 'photos',
  url: 'photos',
  more: undefined
};
const Birthday = {
  name: 'Anniversaire',
  type: 'event',
  url: 'anniversaire',
  more: undefined
};
const Location = {
  name: 'Location',
  type: 'Location',
  url: 'location',
  more: undefined
};
const Infos = {
  name: 'Infos',
  type: 'infos',
  url: 'infos',
  more: undefined
};
const Animations = {
  name: 'Animations',
  type: 'Animations',
  url: 'animations',
  more: undefined
};
// const Blog = {
//   name: 'Blog',
//   type: 'blog',
//   url: 'blog',
//   more: undefined
// };
const Links = [
  Home,
  Courses,
  Stages,
  Photos,
  Animations,
  Location,
  Birthday,
 Infos,
//  Blog,
];

export default Links;
