import React from 'react';
// import { Link } from 'react-router-dom';

const Team = () => {

  return (
    <div>
      <h2>L'équipe</h2>
      <div className="team-perso">
        <h4>Didier Oriol</h4>
        <div className="flex">
          <img alt="Didier Oriol" src="images/team/didier.jpg" />
          <div className="team-perso-text">
            <p>Fasciné par le mouvement, la performance et la précision de l’instant, c’est tout naturellement que je me suis dirigé vers le cirque.<br/>
                Une jeunesse rythmée par énormément de sport et des études secondaires en éducation physique m’ont orienté vers un métier qui réunit toutes mes passions. Des rencontres avec des jongleurs, des danseurs, des performers de rue m’ont convaincu de me lancer dans le monde du spectacle.</p>
            <p>Bien décidé à augmenter mon niveau technique et artistique, j’ai suivi avec succès les cours de l’E.N.A.C. (actuellement E.S.A.C.) à Bruxelles en 1998.<br/>
              Depuis 15 ans, je vis de mon art, au travers d’expériences en tout genre. Je travaille actuellement au développement et à l’accompagnement de Créacirque et c’est avec passion que je communique mon savoir.</p>
            <p>Si le cirque c’est la vie, pour moi c’est ma vie !</p>
          </div>
        </div>
      </div>
      <div className="team-perso">
        <h4>Antoine Dethy</h4>
        <div className="flex">
          <img alt="Antoine Dethy" src="images/team/antoine.png" />
          <div className="team-perso-flex">
            {/* <p>Après avoir participé à des cours de jonglerie et d’acrobatie donnés par Didier Oriol (actuellement directeur et pédagogue à Créacirque),</p>
            <p>j’ai également suivi avec intérêt divers stages de théâtre et de mime à Bruxelles et à Namur. J’ai ensuite participé à trois ans de formation à l’E.S.A.C (Ecole Supérieure des Arts du Cirque) pour perfectionner mon art du diabolo qui est ma spécialisation dans le milieu du cirque.</p>
            <p>J’y ai également appris la dance contemporaine, l’interprétation (théâtre) et ai encore progressé en acrobatie.</p> */}
            <p>Jongleur depuis son enfance, il découvre le diabolo à huit ans.
              Il ne le lâchera plus.</p>
            <p>Il suivra, à différentes périodes, des cours de jonglerie et acrobatie avec Didier Oriol et inventera ses propres figures de diabolo.
                Il entre à l'Ecole Supérieur des Arts du Cirque qui le poussera à développer son style, mêlant au diabolo la dance et l'acrobatie.</p>
            <p>Aujourd'hui, artiste de cirque professionnel, il a créé son numéro en solo: "Tac!", et travail avec différentes compagnies tel que cie Airblow ou le Duo Tamba.</p>
            <p>Et bien sur, il enseigne la jonglerie sous toutes ses formes.</p>
          </div>
        </div>
      </div>
      <div className="team-perso">
        <h4>Nell Georges</h4>
        <div className="flex">
          <img alt="Nell Georges" src="images/team/nell.jpg" />
          <div className="team-perso-flex">
            <p>Je m'appelle Nell. Je fais du cirque aérien depuis toute petite.</p>
            <p>J'ai la chance de faire également de la musique et d'être régisseuse de théâtre.  Les arts vivants, c'est ma deuxième maison !</p>
            <p>Je me sers de mes connaissances dans différents domaines pour les cours d'aérien.  Au programme : de la technique (tissu, trapèze, cerceau), une conscience de son corps, des mouvements qui nous entourent et nous traversent, du contact humain, de la bienveillance, de la musique, un peu de folie <span role="img" aria-label='folie'>🎉</span> et un brin de magie <span role="img" aria-label='magie'>✨</span>.</p>
          </div>
        </div>
      </div>
    </div>
)
};

export default Team;
