import React from 'react';
import '../css/footer.scss';
import {LastUpdated} from '../constants/Numbers';

const Footer = () => (
  <footer>
    <hr />
    <div className="container footer-container">
      <p id="footer-container-left">
        <span>Créacirque by </span>
        <a target="_blank noreferrer" href="https://usidistrib.com">
          Usidistrib
        </a>
        <span> © 2025</span>
      </p>
      <p id="footer-container-right">
        Dernière mise à jour : {LastUpdated}
      </p>
    </div>
  </footer>
);

export default Footer;
