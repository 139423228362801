import React from 'react'; //, useContext, useEffect 
import '../css/stagedetail.scss';

const StageDetail = () => {
    return (
        <div id="stage-detail">
            <h2>Stage de cirque et acrobatie</h2>
            <p>Du 3 au 7 mars 2025 de 9h00 à 16h00</p>
            <p>Entrez dans l'univers magique du cirque et de l'acrobatie ! <span role="img" aria-label="stars">✨</span></p>
            <img className="big" src="/images/stages/acro.jpg" alt="acrobatie" />

            <p>Offrez à vos enfants une semaine de découverte unique où ils apprendront les différentes techniques 
              de cirque et s'initieront aux sensations de l'acrobatie. 
              Jonglerie, équilibre, trapèze, découverte du mini et grand trampoline et 
              de nombreuses figures acrobatiques seront au rendez-vous, encadrés par un professionnel passionné. 
              Une aventure ludique pour développer confiance en soi, 
              créativité et esprit d'équipe. 
              Rejoignez-nous pour une expérience qui les fera rêver ! <span role="img" aria-label="star">🌟</span></p>
              {/* <div className="flex">
                <img src="/images/stages/jonglerie.jpg" alt="acrobatie" />
                <img src="/images/stages/mono.jpg" alt="acrobatie" />
                <img src="/images/stages/trapeze.jpg" alt="acrobatie" />
              </div> */}

            <h4>Pour les enfants de 6 ans à 15 ans.</h4>

            <p><b>Lieu :</b> Salle 2 de l'Institut Sainte-Marie <br/>
                227, rue de l'Aurore à 5100 Jambes</p>
            <p><b>Horaires :</b>  De 9h à 16h</p>
            <p><b>Prix :</b> 115€<br/>
                (- 10% si famille nombreuse ou 2ème enfant inscrit de la même famille).</p>
            <p><b>Infos et réservations :</b> Envoyer un mail à : <a href="mailto:creacirque@gmail.com" title="Mail Creacirque">creacirque@gmail.com</a></p>
        </div>
    )
}

export default StageDetail;



        // <div id="stage-detail">
        //     <h2>Stage de cirque à Burdinne</h2>
        //     <p>Du 21/10/2024 au 24/10/2024 de 09:00 à 16:00</p>
        //     <p>Ecole de Burdinne - Burdinne 60€</p>

        //     <h3>Stage d'initiation de cirque</h3>
        //     <h4>Du 21 au 25 octobre 2024</h4>
        //     <p><i>En collaboration avec le service ATL de Burdinne</i></p>
        //     <p>Nous découvrirons l’art du cirque selon notre âge et notre développement. Nous aurons l'opportunité de développer notre agilité,
        //     notre créativité et notre confiance en nous, tout en s'amusant !</p>

        //     <h4>Pour les enfants de 2,5 ans à 12 ans.</h4>

        //     <p><b>Lieu :</b> Ecole de Burdinne<br/>
        //       Rue de la Fontaine - Burdinne</p>
        //     <p><b>Horaires :</b> Animations de 9h à 16h<br/>
        //       Garderie gratuite de 8h à 18h</p>
        //     <p><b>Prix :</b> 60€<br/>
        //       (Tarif dégressif 5€ pour les enfants suivants).</p>
        //     <p><b>Infos et réservations :</b> Les réservations se font via le Portail parents du site de la Commune.<br/>
        //       https://burdinne.guichet-citoyen.be/portail_parent/</p>
        //     <p><b>Contact :</b> Coordinatrice ATL<br/>
        //       085/51.97.19 ou atl@burdinne.be</p>
        //     <p>atl@burdinne.be</p>
        //     <p><b>Ecole de Burdinne</b> Rue de la Fontaine, Burdinne</p>
        // </div>
