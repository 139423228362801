import React from 'react';
// import { Link } from 'react-router-dom';

const Birthday = () => {

  return (
    <div id="birthday">
      <div id="title">
        <h1>Anniversaire</h1>
      </div>
      <div id="content">
        <div className="row-content">
          <h2 className="row-title">
            Venez fêter votre anniversaire à Créacirque!
          </h2>
          <div className="row" data-role="line">

            {/* style="width:100%" */}
            <div data-role="cell" className="column" >
              <div id="cell-5c3efa0d479955c9d3fc9ae6" className="column-content">
                <p>Le saviez-vous? Créacirque organise aussi des anniversaires sur le thème du cirque!</p>

                <p>2 formules sont possibles:</p>

                <p>- Anniversaire "découverte du cirque": Avec ses copain.iness, l'enfant pourra partager les joies du cirque: jonglerie, trapèze, acrobatie...</p>

                <p>Une succession de jeux ludiques et d'exercices d'initiation aux différentes techniques de cirque se succèdent. Ensuite, une pause gâteau est prévue si vous le souhaitez. L'animation se clôture par une sculpture de ballons pour chaque enfant!</p>

                <p><img alt="Image1 1" height="395" src="/images/image1-1.png" width="523"/></p>

                <p>- Anniversaire "découverte du grand trampoline": Entouré.e de tous ses ami.e.s, l'enfant sera plongé dans un monde de sensations acrobatiques inoubliables!</p>

                <p><img alt="Image2" height="402" src="/images/image2.png" width="544"/></p>

                <p>Durée: 3 heures</p>

                <p>Pour maximum&nbsp;12 enfants pour les anniversaires " trampoline" et 15 enfants pour les anniversaires "découverte du cirque".</p>

                <p>A partir de 6 ans (1 ère primaire) pour les anniversaires cirque et 12 ans pour les anniversaires grand trampoline.</p>

                <p>Possibilité aussi de se faire grimer en animal fantastique, super héros.ïne ou tout tout autre personnage issu de l'imagination des enfants.</p>

                <p>Les anniversaires se font dans la salle de Créacirque ( Institut Ste-Marie de Jambes : 227, rue de l'aurore) le samedi de 13h30 à 16h30 et le dimanche de 14h à 17h.</p>

                <p>&nbsp;</p>

                <p>Si vous désirez plus d'informations,&nbsp;envoyez un mai à <a href="mailto:creacirque@gmail.com">creacirque@gmail.com</a></p>

                <p>&nbsp;A bientôt...</p>

                <p>&nbsp;</p>
              </div>
            </div>
          </div>
        </div>      </div>
    </div>
  )
};

export default Birthday;
