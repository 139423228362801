import React, { useEffect, useState } from 'react'; // , useContext
// import { Link } from 'react-router-dom';
import Profile from '../components/Profile';
import axiosInstance from "../helpers/Axios";
import '../css/youraccount.scss';

interface IProfil {
  contactId: number;
  firstName: string;
  lastName: string;
  birthdate: string;
  address: string;
  street: string;
  postalCode: string;
  city: string;
  phoneNumber: string;
  phoneNumber2: string;
  phoneNumber3: string;
  email: string;
  imageURI: string;
  parents: IProfil[];
  isVisible: boolean;
  dateCreation: Date;
  dateModification: Date;
  children: IProfil[];
}

const defaultProfil: IProfil = {
  contactId: -1,
  firstName: '',
  lastName: '',
  birthdate: '',
  address: '',
  street: '',
  postalCode: '',
  city: '',
  phoneNumber: '',
  phoneNumber2: '',
  phoneNumber3: '',
  email: '',
  imageURI: '',
  parents: [],
  isVisible: true,
  dateCreation: new Date('2024-02-02'),
  dateModification: new Date('2024-02-02'),
  children: []
}


const YourAccount = () => {

  const [profil, setProfil] = useState(defaultProfil);
  const [visible, setVisible] = useState(true);
  const loading = profil === defaultProfil && visible;
  const [url, setUrl] = useState('');


  useEffect(() => {

    console.log('path', window.location.href);
    const params = window.location.href.split('?');
    console.log('params', params[1]);
    const paramList = params[1].replaceAll('%C3%A9', 'é').split('&');
    console.log('paramList', paramList);

    if (paramList.length === 3) {
      const id = paramList[0].split('=')[1];
      const firstName = paramList[1].split('=')[1].replaceAll('%20', '-').toLowerCase();
      const lastName = paramList[2].split('=')[1].toLowerCase();
      console.log('id', id);
      console.log('firstName', firstName);
      console.log('lastName', lastName);
      setUrl(`${id}-${firstName}-${lastName}`);
    }


    console.log('loading', loading);
    if (loading) {
      if(!url)
        return;
        console.log('url', `/contact/${url}`);
 
      axiosInstance.get(`/contact/${url}`)
        .then(response => {
          //console.log('response', response);

          setProfil(response.data);//.map((courseObj : ICourseObj) => courseObj.course))
          console.log("profil", response.data);
          // setUserProfile(response.data);
          // setLocation(response.data.location);
        })
        .catch((err) => {
          const error = err.response === undefined ?
            'Server in not reachable'
            : err.response.data;
          console.log('error', error);
          setVisible(false);
        });
    }
  }, [loading, url]
  );

  //  console.log("list",list);

  return (
    <div id="courses">
      <div id="title">
        <h1>Votre profil</h1>
      </div>
      <div id="content">
        {
          loading ?
            <p>Chargement en cours...</p>
            :
            visible ?
              <Profile profil={profil} isChild={false} />
              :
              <div>
                <p>Les profils ne sont actuellement pas disponibles</p>
              </div>
        }
      </div>
      <div id="documents">
        Voici les documents :
        <ul>
          <a target="_blank" rel="noopener noreferrer" download href="https://creacirque.be/Documents/Cr%C3%A9acirque%20-%20Droits%20image.pdf">
            <li className="documents__item">Droits à l'image</li>
          </a>
          <a target="_blank" rel="noopener noreferrer" download href="https://creacirque.be/Documents/Cr%C3%A9acirque%20-%20Licences.pdf">
            <li className="documents__item">Licences</li>
          </a>
          <a target="_blank" rel="noopener noreferrer" download href="https://creacirque.be/Documents/Cr%C3%A9acirque%20-%20Informations%20complémentaires.pdf">
            <li className="documents__item">Informations complémentaires</li>
          </a>
        </ul>
      </div>
    </div>
  )
};

export default YourAccount;
